<template>
  <div class="p-3">
    <b-row>
      <b-col sm="6" cols="12">
        <InputText
          textFloat="Total Campaign Budget"
          placeholder="Total Campaign Budget"
          type="number"
          name="total_campaign_budget"
          v-model="form.budget"
      /></b-col>
      <b-col md="6" class="d-sm-block d-none"></b-col>
      <b-col sm="6" cols="12">
        <label class="label">Spending Tracking From</label>
        <div class="input-container">
          <datetime
            type="date"
            class="date-picker"
            placeholder="Please Select Date"
            value-zone="Asia/Bangkok"
            ref="trackingFrom"
            v-model="form.tracking_from"
          >
          </datetime>
          <div
            :class="'icon-container text-right'"
            @click="$refs.trackingFrom.isOpen = true"
          >
            <font-awesome-icon
              icon="calendar-alt"
              :class="'pointer color-primary'"
              color="#B41BB4"
            />
          </div>
        </div>
      </b-col>
      <b-col sm="6" cols="12">
        <label class="label">Spending Tracking To</label>
        <div class="input-container">
          <datetime
            type="date"
            class="date-picker"
            placeholder="Please Select Date"
            value-zone="Asia/Bangkok"
            ref="trackingTo"
            :min-datetime="form.tracking_from"
            v-model="form.tracking_to"
          >
          </datetime>
          <div
            :class="'icon-container text-right'"
            @click="$refs.trackingTo.isOpen = true"
          >
            <font-awesome-icon
              icon="calendar-alt"
              :class="'pointer color-primary'"
              color="#B41BB4"
            />
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import InputText from "@/components/inputs/InputText";
export default {
  components: {
    InputText,
  },
  props: { form: { required: true }, v: { required: true } },
};
</script>
<style lang="scss">
.label {
  font-size: 15px;
  margin-bottom: 2px;
  font-weight: bold;
  color: black;
}
</style>
