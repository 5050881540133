<template>
  <div>
    <div class="header-secondary title-panel header-title d-none d-sm-block">
      Success Response
    </div>
    <div class="pt-2 container">
      <TextEditorsTiny
        textFloat="Description"
        placeholder="Description"
        :value="form.response_success"
        @onDataChange="(val) => (form.response_success = val)"
        v-model="form.response_success"
      />
    </div>
    <div class="header-secondary title-panel header-title d-none d-sm-block">
      Fail Response
    </div>
    <div class="container py-2">
      <InputText
        textFloat="Description"
        placeholder="Description"
        type="text"
        name="Description"
        v-model="form.response_fail"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    form: {
      required: true,
    },
  },
};
</script>

<style></style>
